import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { setTitle } from "../../../helpers/functions"
import { API_URL } from "../../../config"

import "./styles.scss"

const Company = props => {
  setTitle("Company")

  const [master, setMaster] = useState({})
  const [tableData] = useState([])
  const [selectedState] = useState(null)
  const [selectedDistrict] = useState(null)

  const formRef = useRef()

  const { states, districts } = useSelector(state => state.Options)

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Company ID", field: "uniqueId" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Contact Person", field: "person" },
      { label: "Mobile", field: "mob" },
      { label: "District", field: "district" },
      { label: "Created By", field: "staff" },
      { label: "Action", field: "action" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Company" />

          <Card>
            <CardBody>
              <AvForm ref={formRef} className="needs-validation">
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Company Name</Label>
                      <AvField
                        name="name"
                        placeholder="Company Name"
                        type="text"
                        errorMessage="Enter Company Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Short Code</Label>
                      <AvField
                        name="shortCode"
                        placeholder="Short Code"
                        type="text"
                        errorMessage="Enter Short code"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Enter Short Code",
                          },
                          pattern: {
                            value: /^[A-Z]{3,4}$/,
                            errorMessage:
                              "Short Code must be 3 to 4 uppercase letters",
                          },
                          minLength: {
                            value: 3,
                            errorMessage:
                              "Short Code must be at least 3 characters long",
                          },
                          maxLength: {
                            value: 4,
                            errorMessage:
                              "Short Code must be no more than 4 characters long",
                          },
                        }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Contact Person</Label>
                      <AvField
                        name="person"
                        placeholder="Contact Person"
                        type="text"
                        errorMessage="Enter Contact Person"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            errorMessage:
                              "Only alphabets and spaces are allowed",
                          },
                        }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Designation</Label>
                      <AvField
                        name="design"
                        placeholder="Designation"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Mobile No.</Label>
                      <AvField
                        name="mob"
                        placeholder="Mobile No."
                        type="text"
                        errorMessage="Enter valid mobile no"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "(0|91)?[6-9][0-9]{9}",
                          },
                        }}
                        id="validationCustom03"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Landline No.</Label>
                      <AvField
                        name="land"
                        placeholder="Landline No."
                        type="text"
                        errorMessage="Enter valid 11 digit number"
                        className="form-control"
                        validate={{
                          pattern: {
                            value: "^[0-9]{11}$",
                          },
                        }}
                        id="validationCustom04"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Email ID</Label>
                      <AvField
                        name="email"
                        placeholder="Email ID"
                        type="email"
                        errorMessage=" Please provide a valid Email ID."
                        className="form-control"
                        validate={{ email: true }}
                        id="validationCustom05"
                        value={master?.email || ""}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Website</Label>
                      <AvField
                        name="web"
                        placeholder="Website"
                        type="text"
                        className="form-control"
                        id="validationCustom05"
                        value={master?.web || ""}
                        validate={{
                          pattern: {
                            value: /^(https?:\/\/[^\s$.?#].[^\s]*)$/,
                            errorMessage:
                              "Enter a valid URL (e.g. https://example.com)",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Address</Label>
                      <AvField
                        name="address"
                        placeholder="Address"
                        type="text"
                        className="form-control"
                        id="validationCustom03"
                        value={master?.address || ""}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>State</Label>
                      <Select
                        name="state_id"
                        value={selectedState}
                        options={states}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>District</Label>
                      <Select
                        name="district_id"
                        value={selectedDistrict}
                        options={selectedState?.value ? districts : []}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Pincode</Label>
                      <AvField
                        name="pin"
                        placeholder="Pincode"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        validate={{
                          pattern: {
                            value: "^[0-9]{6}$",
                            errorMessage: "Enter a valid pincode",
                          },
                        }}
                        value={master?.pin || ""}
                        min={0}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">GSTIN</Label>
                      <AvField
                        name="gstin"
                        placeholder="GSTIN"
                        type="text"
                        className="form-control"
                        id="validationCustom03"
                        validate={{
                          pattern: {
                            value:
                              "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$",
                            errorMessage: "Enter a valid GSTIN",
                          },
                        }}
                        value={master?.gstin || ""}
                        min={0}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">PAN No.</Label>
                      <AvField
                        name="pan"
                        placeholder="PAN No"
                        type="text"
                        className="form-control"
                        id="validationCustom04"
                        validate={{
                          pattern: {
                            value: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                            errorMessage: "Enter a valid PAN No.",
                          },
                        }}
                        value={master?.pan || ""}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">CIN No.</Label>
                      <AvField
                        name="cin"
                        placeholder="CIN No."
                        type="text"
                        className="form-control"
                        id="validationCustom05"
                        validate={{
                          pattern: {
                            value:
                              "^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$",
                            errorMessage: "Enter a valid CIN No.",
                          },
                        }}
                        value={master?.cin || ""}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">TDS No.</Label>
                      <AvField
                        name="tds"
                        placeholder="TDS No"
                        type="text"
                        className="form-control"
                        id="validationCustom05"
                        validate={{
                          pattern: {
                            value: "^[A-Z]{4}[0-9]{5}[A-Z]{1}$",
                            errorMessage: "Enter a valid TDS No.",
                          },
                        }}
                        value={master?.tds || ""}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Latitude</Label>
                      <AvField
                        name="latitude"
                        placeholder="Latitude"
                        type="number"
                        className="form-control"
                        id="validationCustom03"
                        value={master?.latitude || ""}
                        //value={0}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Longitude</Label>
                      <AvField
                        name="longitude"
                        placeholder="Longitude"
                        type="number"
                        className="form-control"
                        id="validationCustom04"
                        value={master?.longitude || ""}
                        //value={0}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">
                        Company Logo (200x100)
                      </Label>
                      {master?.logo ? (
                        <div div className="img-wraps d-flex flex-column">
                          <img
                            alt=""
                            width="200"
                            height="100"
                            src={`${API_URL}${master.logo}`}
                          />
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={() =>
                              setMaster(pre => ({ ...pre, logo: null }))
                            }
                            type="button"
                            style={{ width: "200px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <input
                          name="logo"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                        />
                      )}
                    </div>
                  </Col>

                  <Col md={2} className="d-flex align-items-center mt-2 pt-2">
                    <Button
                      color={master?.id ? "warning" : "primary"}
                      className="me-2"
                      type="submit"
                    >
                      {master?.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" type="button">
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                data={data}
                id="companyTable"
                page={1}
                totalPage={1}
                onChange={() => {}}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Company
