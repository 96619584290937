import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect, useDispatch, useSelector } from "react-redux"
import { Form, Input, Button, Row, Col } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// import logoSm from "../../assets/images/logo-sm.svg"
// import logoDark from "../../assets/images/logo-dark.png"

import logoSm from "../../assets/images/asap-logo-sm.png"
import logoDark from "../../assets/images/asap-logo.png"

import { withTranslation } from "react-i18next"

import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import { getModule } from "../../store/module/actions"
import Cookies from "js-cookie"
import { getMenu } from "../../store/menu/actions"
import { API_URL } from "../../config"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  const dispatch = useDispatch()

  const module = useSelector(state => state.Module.data)

  const navigate = useNavigate()

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    var windowSize = document.documentElement.clientWidth

    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
    if (windowSize > 991) {
      body.getAttribute("data-sidebar-size") === "sm" && windowSize > 991
        ? body.setAttribute("data-sidebar-size", "lg")
        : body.setAttribute("data-sidebar-size", "sm")
    }
  }

  useEffect(() => {
    dispatch(getModule(JSON.parse(localStorage.getItem("authUser")).privilage))
  }, [dispatch])

  function handleModule(data) {
    sessionStorage.setItem("module", data.moduleName)
    sessionStorage.setItem("module_code", data.shortCode)
    sessionStorage.setItem("module_id", data._id)
    sessionStorage.setItem("module_url", data.redirect_url)
    if (Cookies.get("_token") && localStorage.getItem("authUser")) {
      const user = JSON.parse(localStorage.getItem("authUser"))._id
      dispatch(getMenu({ user, module: data._id }))
      navigate(data.redirect_url)
      setsocialDrp(!socialDrp)
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                  {/* <img src={logoDark} alt="" height="20" /> */}
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                  {/* <img src={logoLight} alt="" height="20" /> */}
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <Dropdown
              className="d-none d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search)
              }}
              type="button"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                tag="button"
              >
                {" "}
                <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            {/* <LanguageDropdown /> */}

            <Dropdown
              className="d-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button"
              >
                <i className="uil-apps"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" end>
                <div className="px-lg-2">
                  <Row className="g-0">
                    {module &&
                      module.map((el, index) => {
                        let color = ""
                        if (sessionStorage.getItem("module_id") === el._id) {
                          color = "#dddced"
                        }
                        return (
                          <Col
                            key={el._id}
                            md="4"
                            id={"module" + el?.moduleName}
                            className="module_box"
                            style={{ backgroundColor: color }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              className="dropdown-icon-item"
                              // to="#"
                              onClick={() => {
                                if (
                                  sessionStorage.getItem("module_id") !== el._id
                                ) {
                                  handleModule(el)
                                }

                                setsocialDrp(!socialDrp)
                              }}
                            >
                              <img
                                src={`${API_URL}uploads/module_images/${el.icon}`}
                                alt=""
                              />
                              <span>{el?.moduleName}</span>
                            </span>
                          </Col>
                        )
                      })}
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-minus-path"></i>
              </button>
            </Dropdown>

            <button
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <Link to={"/logout"}>
                <i className="uil uil-power"></i>
              </Link>
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  // changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  // changeSidebarType,
})(withTranslation()(Header))
