import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { setTitle } from "../../../helpers/functions"

import "./styles.scss"
import TablePagination from "../../../components/Common/TablePagination"

const Branch = () => {
  setTitle("Branch")

  const formRef = useRef()

  const { states, companies, districts } = useSelector(state => state.Options)

  const [master] = useState({})
  const [tableData] = useState([])

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Contact Person", field: "contactPerson" },
      { label: "Designation", field: "designation" },
      { label: "Mobile", field: "mobile" },
      { label: "City", field: "city" },
      { label: "Created By", field: "staff" },
      { label: "Action", field: "action" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Branch" />

          <Card>
            <CardBody>
              <AvForm ref={formRef} className="needs-validation">
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <AvField
                        name="name"
                        placeholder="Name"
                        type="text"
                        errorMessage="Enter Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Company</Label>
                      <Select
                        name="company"
                        errorMessage="Select Company"
                        options={companies}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Short Code</Label>
                      <AvField
                        name="shortCode"
                        placeholder="Short Code"
                        type="text"
                        errorMessage="Enter Short code"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Enter Short Code",
                          },
                        }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Contact Person</Label>
                      <AvField
                        name="contactPerson"
                        placeholder="Contact Person"
                        type="text"
                        errorMessage="Enter Contact Person"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            errorMessage:
                              "Only alphabets and spaces are allowed",
                          },
                        }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Designation</Label>
                      <AvField
                        name="designation"
                        placeholder="Designation"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Mobile No.</Label>
                      <AvField
                        name="mobile"
                        placeholder="Mobile No."
                        type="text"
                        errorMessage="Enter valid 10 digit number"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          maxLength: { value: 10 },
                          pattern: {
                            value: "[6-9][0-9]{9}",
                          },
                        }}
                        id="validationCustom03"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Landline No.</Label>
                      <AvField
                        name="landLineNumber"
                        placeholder="Landline No."
                        type="text"
                        errorMessage="Enter valid 11 digit number"
                        validate={{
                          pattern: {
                            value: "^[0-9]{11}$",
                          },
                        }}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Email ID</Label>
                      <AvField
                        name="email"
                        placeholder="Email ID"
                        type="email"
                        errorMessage=" Please provide a valid Email ID."
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>State</Label>
                      <Select
                        name="state"
                        options={states}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>District</Label>

                      <Select
                        name="district"
                        options={districts}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label>City</Label>
                      <AvField
                        name="city"
                        placeholder="City"
                        type="text"
                        className="form-control"
                        id="validationCustom03"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Pincode</Label>
                      <AvField
                        name="pincode"
                        placeholder="Pincode"
                        type="text"
                        className="form-control"
                        validate={{
                          pattern: {
                            value: "^[0-9]{6}$",
                            errorMessage: "Enter a valid pincode",
                          },
                        }}
                        id="validationCustom05"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Latitude</Label>
                      <AvField
                        name="latitude"
                        placeholder="Latitude"
                        type="number"
                        errorMessage="Enter Latitude"
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Longitude</Label>
                      <AvField
                        name="longitude"
                        placeholder="Longitude"
                        type="text"
                        errorMessage="Enter Longitude"
                        className="form-control"
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Address</Label>
                      <AvField
                        name="address"
                        placeholder="Address"
                        type="textarea"
                        errorMessage="Enter Your Address"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        rows={1}
                      />
                    </div>
                  </Col>

                  <Col md="3" className="d-flex align-items-center mt-2 pt-2">
                    <Button
                      color={master.id ? "warning" : "primary"}
                      type="submit"
                    >
                      {master.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" className="ms-3">
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                data={data}
                id="companyTable"
                page={1}
                totalPage={1}
                onChange={() => {}}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Branch
