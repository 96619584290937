export const typeValues = {
  1: "Private",
  2: "Public",
  3: "Government",
  4: "Deemed",
}
export const typeOptions = [
  { label: "Private", value: 1 },
  { label: "Public", value: 2 },
  { label: "Government", value: 3 },
  { label: "Deemed", value: 4 },
]

export const sampleCollegeData = [
  {
    type: 4,
    date: "2024-10-08",
    name: "College of Engineering Trivandrum",
    shortCode: "CET",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "Rajiv Gandhi Institute of Technology",
    shortCode: "RIT",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "Government Engineering College Thrissur",
    shortCode: "GECT",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "TKM College of Engineering",
    shortCode: "TKM",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "Model Engineering College",
    shortCode: "MEC",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "NSS College of Engineering",
    shortCode: "NSSCE",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "Mar Athanasius College of Engineering",
    shortCode: "MACE",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "Government College of Engineering Kannur",
    shortCode: "GCEK",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
  {
    type: 4,
    date: "2024-10-08",
    name: "LBS Institute of Technology for Women",
    shortCode: "LBSITW",
    university: { name: "KTU" },
    addedBy: { name: "Admin SRV" },
    mobile: 9696969696,
    email: "srv@srvinfotech.in",
  },
]
