import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { dateConverter, setTitle } from "../../../helpers/functions"
import { del, get, post, put } from "../../../helpers/api_helper"

import "./styles.scss"
import { sampleCollegeData, typeOptions, typeValues } from "./helper"
import { API_URL } from "../../../config"

const College = () => {
  setTitle("College")

  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const [universityOptions, setUniversityOptions] = useState([])
  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    handleTableData()
    handleUniversityOptions()
  }, []) // eslint-disable-line

  const handleUniversityOptions = async () => {
    try {
      const response = await get(`options/university`)
      setUniversityOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      // const query = `?page${currentPage}&limit=${limit}`
      // const response = await get(`/college${query}`)

      const data = sampleCollegeData
      const count = 9

      data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.type = typeValues[item.type]
        item.university = item.university?.name
        item.staff = item.addedBy?.name

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i className="fas fa-eye eye-icon"></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      // let response
      // if (masterObject.id) response = await put(`college`, masterObject)
      // else response = await post(`college`, masterObject)
      // reset()
      // toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = data => {
    toTop()
    // TODO: API Call
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      // const response = await del(`college/${id}`)
      // toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      fd.append("logo", e.target.files[0])
      const response = await post(`college/logo`, fd)
      handleValueChange({ value: response.data.new_filename, name: "logo" })
    } catch (error) {
      toastr.error(error.response.data.message)
      e.target.value = ""
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    setMasterObject({})
    setSelectedFields({})

    handleTableData(1)

    setPage(1)
    setTotalPage(1)
    toTop()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "University", field: "university" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Mobile", field: "mobile" },
      { label: "Type", field: "type" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="College" />

          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                onValidSubmit={handleSubmit}
                id="collegeForm"
              >
                <Row>
                  <Col md="3">
                    <Label>State</Label>
                    <Select
                      menuPosition="fixed"
                      options={[]}
                      value={selectedFields.state || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "state")
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Label>University</Label>
                    <Select
                      menuPosition="fixed"
                      options={universityOptions}
                      value={selectedFields.university || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "university")
                      }
                    />
                  </Col>

                  <Col md="3">
                    <Label>Name</Label>
                    <AvField
                      name="name"
                      placeholder="Name"
                      errorMessage="Enter Name"
                      validate={{ required: { value: true } }}
                      value={masterObject.name || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Short Code</Label>
                    <AvField
                      name="shortCode"
                      placeholder="Short Code"
                      type="text"
                      errorMessage="Enter Short Code"
                      validate={{ required: { value: true } }}
                      value={masterObject.shortCode || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Logo</Label>
                    {masterObject?.logo ? (
                      <div div className="img-wraps d-flex flex-column">
                        <img
                          alt=""
                          width="150"
                          height="150"
                          src={`${API_URL}${masterObject.logo}`}
                        />
                        <button
                          className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                          onClick={() => {
                            // handleValueChange("", "logo")
                          }}
                          style={{ width: "150px" }}
                          type="button"
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <Tooltip
                        title="(Invoice, Images, PDF)"
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <AvField
                            name="file"
                            type="file"
                            rows="1"
                            accept="image/*, .pdf, .xls, .xlsx"
                            onChange={handleFileUpload}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </Col>

                  <Col md="3">
                    <Label>Type</Label>
                    <Select
                      menuPosition="fixed"
                      options={typeOptions}
                      value={selectedFields.type || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "type")
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Label>Email</Label>
                    <AvField
                      name="email"
                      placeholder="Email"
                      type="text"
                      errorMessage="Enter Email"
                      value={masterObject.email || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Mobile</Label>
                    <AvField
                      name="mobile"
                      placeholder="Mobile"
                      type="text"
                      errorMessage="Enter Mobile"
                      value={masterObject.mobile || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Website Url</Label>
                    <AvField
                      name="website"
                      placeholder="Website Url"
                      type="text"
                      errorMessage="Enter Website Url"
                      value={masterObject.website || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Address</Label>
                    <AvField
                      name="address"
                      placeholder="Address"
                      type="text"
                      errorMessage="Enter Address"
                      value={masterObject.address || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md="3">
                    <Label>District</Label>
                    <AvField
                      name="district"
                      placeholder="District"
                      type="text"
                      errorMessage="Enter District"
                      value={masterObject.district || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>City</Label>
                    <AvField
                      name="city"
                      placeholder="City"
                      type="text"
                      errorMessage="Enter City"
                      value={masterObject.city || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md="3">
                    <Label>Contact Person</Label>
                    <AvField
                      name="contactPerson"
                      placeholder="Contact Person"
                      type="text"
                      errorMessage="Enter Contact Person"
                      value={masterObject.contactPerson || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Designation</Label>
                    <AvField
                      name="designation"
                      placeholder="Designation"
                      type="text"
                      errorMessage="Enter Designation"
                      value={masterObject.designation || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Contact Number</Label>
                    <AvField
                      name="contactNumber"
                      placeholder="Contact Number"
                      type="text"
                      errorMessage="Enter Contact Number"
                      value={masterObject.contactNumber || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3" style={{ marginTop: "26px" }}>
                    <div className="d-flex gap-3">
                      <Button color={masterObject.id ? "warning" : "primary"}>
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button color="danger" type="reset" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="collegeTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default College
