import React from "react"
import PropTypes from "prop-types"
import Pagination from "@mui/material/Pagination"
import { MDBDataTable } from "mdbreact"
import "./styles.scss"

const TablePagination = ({
  key,
  tableId,
  data = [],
  page = 1,
  count,
  onChange,
}) => {
  return (
    <React.Fragment>
      <MDBDataTable
        id={tableId}
        data={data}
        bordered
        key={key}
        responsive
        searching={false}
        entries={20}
        paging={false}
        info={false}
        sortable={false}
        disableRetreatAfterSorting={true}
      ></MDBDataTable>
      <Pagination
        shape="rounded"
        key={page}
        id="paginationComponent"
        page={page}
        onChange={(e, value) => onChange(value)}
        count={count}
      />
    </React.Fragment>
  )
}

TablePagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  data: PropTypes.array,
  tableId: PropTypes.string,
  onChange: PropTypes.func,
}

export default TablePagination
