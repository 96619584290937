import moment from "moment"

import { APP_NAME, CRYPTO_HASH } from "../config"

import CryptoJS from "crypto-js"

export const setTitle = name => (document.title = name + ` | ${APP_NAME} `)

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export const getFirstDay = date =>
  moment().startOf("month").format("YYYY-MM-DD")

export const getDate = () => moment().format("YYYY-MM-DD")
export const getTime = () => moment().format("HH:mm:ss")

export const dateConverter = date => moment(date).format("DD-MM-YYYY")
export const timeConverter = time => moment(time, "HH:mm:ss").format("hh:mm a")

export function encrypt(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_HASH,
  ).toString()
  return encrypted
}

export const decrypt = data => {
  const decrypted = CryptoJS.AES.decrypt(data, CRYPTO_HASH).toString(
    CryptoJS.enc.Utf8,
  )
  return decrypted
}

export const handleImageValidation = async (
  file,
  width = 200,
  height = 100,
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        if (img.width === width && img.height === height) {
          resolve(true)
        } else {
          reject(
            `Please upload an image with dimensions ${width}x${height} pixels.`,
          )
        }
      }
    }

    reader.readAsDataURL(file)
  })
}
