import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "../Common/withRouter"

import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import logo from "../../assets/images/asap-logo-sm.svg"
import logoDark from "../../assets/images/asap-logo.svg"

const Sidebar = props => {
  function tToggle() {
    var body = document.body
    var windowSize = document.documentElement.clientWidth

    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")

    if (windowSize > 991) {
      body.getAttribute("data-sidebar-size") === "sm"
        ? body.setAttribute("data-sidebar-size", "lg")
        : body.setAttribute("data-sidebar-size", "sm")
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box px-0">
          <Link to="/" className="logo logo-dark  ">
            <span className="logo-sm text-center">
              <img src={logo} alt="" height="60" />
            </span>
            <span className="logo-lg" style={{ padding: "28px" }}>
              <img
                src={logoDark}
                className="w-90"
                alt=""
                height="40"
                style={{ margin: "5px 0px", height: "55px" }}
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light ">
            <span className="logo-sm text-center">
              <img src={logo} alt="" height="40" />
            </span>
            <span className="logo-lg" style={{ padding: "28px" }}>
              <img
                src={logoDark}
                className="w-90"
                alt=""
                height="40"
                style={{ margin: "5px 0px", height: "55px" }}
              />
              {/* <img src={logoLight} alt="" height="20" /> */}
            </span>
          </Link>
        </div>
        <button
          onClick={() => {
            tToggle()
          }}
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars"></i>
        </button>
        <div className="sidebar-menu-scroll">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)))
