import React from "react"
import { Navigate } from "react-router-dom"

import Dashboard from "../pages/Dashboard/CRM/index"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import University from "../pages/Manage/University"
import College from "../pages/Manage/College"

import BlockedIp from "../pages/Security/BlockedIp"
import UserActivity from "../pages/Security/UserActivity"

// Master Settings
import Rules from "../pages/MaterSettings/Rules"
import Branch from "../pages/MaterSettings/Branch"
import Modules from "../pages/MaterSettings/Modules"
import Company from "../pages/MaterSettings/Company"
import Privilege from "../pages/MaterSettings/Privilege"

const defaultRoute = <Navigate to={sessionStorage.getItem("module_url")} />

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/university", component: <University /> },
  { path: "/college", component: <College /> },

  { path: "/user-activity", component: <UserActivity /> },
  { path: "/blocked-ip", component: <BlockedIp /> },

  { path: "/rules", component: <Rules /> },
  { path: "/modules", component: <Modules /> },
  { path: "/company", component: <Company /> },
  { path: "/branch", component: <Branch /> },
  { path: "/privilege", component: <Privilege /> },

  { path: "/", exact: true, component: defaultRoute },
  { path: "*", component: defaultRoute },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { userRoutes, authRoutes }
