import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { dateConverter, setTitle } from "../../../helpers/functions"
import { del, get, post, put } from "../../../helpers/api_helper"
import { getStatesOptions } from "../../../store/options/actions"

import "./styles.scss"
import { API_URL } from "../../../config"

const University = () => {
  setTitle("University")

  const formRef = useRef()
  const dispatch = useDispatch()

  const { states } = useSelector(state => state.Options)

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    dispatch(getStatesOptions({}))
  }, []) // eslint-disable-line

  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      // const query = `?page${currentPage}&limit=${limit}`
      // const response = await get(`/university${query}`)

      const data = [
        {
          name: "APJ Abdul Kalam Technological University (KTU)",
          shortCode: "KTU",
          minCGPA: "7.5",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "Mahatma Gandhi University",
          shortCode: "MGU",
          minCGPA: "6.8",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "Cochin University of Science and Technology",
          shortCode: "CUSAT",
          minCGPA: "8.0",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "University of Kerala",
          shortCode: "UoK",
          minCGPA: "7.0",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "Calicut University",
          shortCode: "CU",
          minCGPA: "6.5",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "Kannur University",
          shortCode: "KU",
          minCGPA: "7.2",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "Kerala Agricultural University",
          shortCode: "KAU",
          minCGPA: "7.0",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "Sree Sankaracharya University of Sanskrit",
          shortCode: "SSUS",
          minCGPA: "6.0",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
        {
          name: "National University of Advanced Legal Studies",
          shortCode: "NUALS",
          minCGPA: "8.5",
          state: {
            name: "Kerala",
          },
          addedBy: {
            name: "Admin SRV",
          },
          date: "2024-10-08",
        },
      ]

      const count = 2

      data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.state = item.state?.name

        item.staff = item.addedBy?.name

        if (item.logo)
          item.file = (
            <a href={API_URL + item.logo} target="_blank" rel="noreferrer">
              <i className="far fa-file-image file-icon"></i>
            </a>
          )

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item)}
              ></i>
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      // let response
      // if (masterObject.id) response = await put(`university`, masterObject)
      // else response = await post(`university`, masterObject)
      // reset()
      // toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = data => {
    toTop()
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      // const response = await del(`university/${id}`)
      // toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    setMasterObject({})
    setSelectedFields({})

    handleTableData(1)

    setPage(1)
    setTotalPage(1)
    toTop()
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "State", field: "state" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Minimum CGPA", field: "minCGPA" },
      { label: "Logo", field: "logo" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="University" />

          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                onValidSubmit={handleSubmit}
                id="universityForm"
              >
                <Row>
                  <Col md="3">
                    <Label>State</Label>
                    <Select
                      menuPosition="fixed"
                      options={states}
                      value={selectedFields.state || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "state")
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Label>Name</Label>
                    <AvField
                      name="name"
                      placeholder="Name"
                      errorMessage="Enter Name"
                      validate={{ required: { value: true } }}
                      value={masterObject.name || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Short Code</Label>
                    <AvField
                      name="shortCode"
                      placeholder="Short Code"
                      type="text"
                      errorMessage="Enter Short Code"
                      validate={{ required: { value: true } }}
                      value={masterObject.shortCode || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Minimum CGPA</Label>
                    <AvField
                      name="minCGPA"
                      placeholder="Minimum CGPA"
                      type="text"
                      errorMessage="Enter Minimum CGPA"
                      validate={{
                        number: true,
                        required: { value: true },
                        max: { value: 10 },
                      }}
                      value={masterObject.minCGPA || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md="3">
                    <Label>Logo</Label>
                    {masterObject?.logo ? (
                      <div div className="img-wraps d-flex flex-column">
                        <img
                          alt=""
                          width="150"
                          height="150"
                          // src={`${API_URL}${masterObject.logo}`}
                        />
                        <button
                          className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                          // onClick={() => {
                          //   handleValueChange("", "logo")
                          // }}
                          style={{ width: "150px" }}
                          type="button"
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <Tooltip
                        title="(Invoice, Images, PDF)"
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <AvField
                            name="file"
                            type="file"
                            rows="1"
                            accept="image/*, .pdf, .xls, .xlsx"
                            // onChange={handleFileUpload}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </Col>

                  <Col md="3" style={{ marginTop: "26px" }}>
                    <div className="d-flex gap-3">
                      <Button color={masterObject.id ? "warning" : "primary"}>
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button color="danger" type="reset" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="universityTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default University
